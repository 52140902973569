<template>
  <div
    class="zq-drop-list"
    @mouseover="onDplOver($event)"
    @mouseout="onDplOut($event)"
  >
    <span :title="dplLable">{{ dplLable }}<i></i></span>
    <ul v-dpl>
      <li
        v-for="(item, index) in dataList"
        :key="index"
        @click="onLiClick(index, $event)"
        :title="item[labelProperty]"
      >
        {{ item[labelProperty] }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropDownList",
  data() {
    return {
      activeIndex: -1
    };
  },
  props: {
    id: {
      type: String
    },
    dataList: {
      type: Array,
      default() {
        return [{ name: "选项一" }, { name: "选项二" }];
      }
    },
    labelProperty: {
      type: String,
      default() {
        return "name";
      }
    }
  },
  directives: {
    dpl: {
      bind(el) {
        el.style.display = "none";
      }
    }
  },
  methods: {
    onDplOver(event) {
      let ul = event.currentTarget.childNodes[1];
      ul.style.display = "block";
    },
    onDplOut(event) {
      let ul = event.currentTarget.childNodes[1];
      ul.style.display = "none";
    },
    onLiClick(index, event) {
      let path = event.path || (event.composedPath && event.composedPath()); //兼容火狐和safari
      path[1].style.display = "none";
      this.activeIndex = index;
      //this.id=event.Id;
      this.$emit("change", {
        index: index,
        value: this.dataList[index]
      });
    }
  },
  computed: {
    dplLable() {
      if (this.activeIndex == -1 && this.id) {
        if (this.dataList.filter(x => x.Id === this.id).length > 0)
          return this.dataList[
            this.dataList.indexOf(
              this.dataList.filter(x => x.Id === this.id)[0]
            )
          ][this.labelProperty];
        else {
          return this.dataList[this.dataList.indexOf(0)][this.labelProperty];
        }
      }
      return this.dataList[this.activeIndex][this.labelProperty];
    }
  }
};
</script>

<style scoped lang="scss">
.zq-drop-list {
  display: inline-block;
  min-width: 100px;
  width: 5.5rem;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  span {
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fcf8f8;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 28px;
    text-overflow: ellipsis;
  }
  ul {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    z-index: 9999;
    max-height: 9.5rem;
    overflow: scroll;
    cursor: pointer;
    li {
      list-style: none;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      // border-bottom: solid 1px #f1f1f1;
      background: #03050c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    li:last-child {
      border-bottom: none;
    }
    li:hover {
      background: #1a5cd7;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    //滑块部分
    border-radius: 5px;
    background-color: rgb(175, 74, 240);
  }
  ::-webkit-scrollbar-track {
    //轨道部分
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 5px;
  }
}
</style>
